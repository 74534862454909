// Colors
$Primary: #08213A;
$PrimaryHover: #021323;
$DarkGray: #222222;
$Gray: #2F2F2F;
$StrokeGray: #E3E3E3;
$LightGray: #737373;
$Success: #0CAD7D;
$Danger: #FF605F;
$DangerDark: #ff0000;
$BGgray: #FAFAFA;
$White: #FFF;

//Typograpy
// DM Sans Font
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap');

h1,h2,h3,h4,h5,h6 {
font-family: 'DM Sans', sans-serif;
font-weight: 500;
color: $DarkGray;
}

h1 {font-size: 26px;@media only screen and (max-width: 767px) {font-size: 22px;}}
h2 {font-size: 24px;@media only screen and (max-width: 767px) {font-size: 20px;}}
h3 {font-size: 20px;@media only screen and (max-width: 767px) {font-size: 18px;}}
h4 {font-size: 18px;@media only screen and (max-width: 767px) {font-size: 16px;}}
h5 {font-size: 16px;@media only screen and (max-width: 767px) {font-size: 14px;}}
h6 {font-size: 14px;@media only screen and (max-width: 767px) {font-size: 14px;}}
p {color:$Gray; font-size: 14px;}
small {color: $Gray; font-size: 12px;}

// Helpers & Mixins
.c-pointer {cursor: pointer;}
.fs-13 {font-size: 13px;}
.fs-11 {font-size: 11px;}
.fw-med {font-weight:500;}
.page-title {font-size: 22px;font-weight: 400;}
.text-gray {color: $LightGray;}

.btn-asphalt, .btn-cancel {
background: $Primary;
color: $White;
font-size: 14px;
padding: 12px 30px;
border-radius: 35px;
    &.min-w {
        width: 185px;
    }
    &:hover {
        background: $PrimaryHover;
        color: $White;
    }
    &.linkedin-btn {
        background: #2867B2;
        color: $White;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 10px 20px;
        &:hover {
            background: #184b89;
        }
    }
    &.btn-gray {
        background: $StrokeGray;
        color: $Gray;
        &:hover {
            background: #cfcfcf;
        }
    }
}
    
.btn-cancel {
    background: $White;
    color: rgba($LightGray, $alpha: 0.6);
    &:hover {
        background: rgba($LightGray, $alpha: 0.2);
        color: $Gray;
    }
}
    
.min-h-unset {
min-height: unset !important;
}

@mixin df-ac-jc {
display: flex;
align-items: center;
justify-content: center;
}

@mixin df-ac {
display: flex;
align-items: center;
}

@mixin trans-all {
transition: all .15s linear;
-moz-transition: all .15s linear;
-webkit-transition: all .15s linear;
}

body {
background: $White;
font: 400 13px "DM Sans", sans-serif;
color: $Gray;
} 

// Signup Style Starts
.left-wrap {
    background: $Primary;
    color: $White;
    min-height: 100vh;
    padding: 80px 70px 0;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    .nav-brand {
        width: 195px;
    }
    h1 {
        font-weight: 400;
        font-size: 30px;
        color: $White;
        margin-top: 90px;
    }
    p {
        font-size: 16px;
        line-height: 27px;
        color: rgba($White, $alpha: 0.6);
        width: 70%;
    }
    .btm-img {
        max-width:80%;
        margin-bottom: 10%;
    }
    .nav-pills {
        .nav-link {
            @include df-ac();
            padding: 0;
            opacity: 0.4;
            color: $White;
            font-size: 14px;
            margin-bottom: 30px;
            position: relative;
            .circle {
                @include df-ac-jc();
                width: 26px;
                height: 26px;
                background: $Primary;
                border: 1px dashed $White;
                border-radius: 50%;
                margin-right: 13px;
                span {
                    font-size: 18px;
                    color: $White;
                    visibility: hidden;
                }
                em {
                    display: none;
                }
            }
            &:after {
                content: "";
                position: absolute;
                left: 13px;
                bottom: 34px;
                width: 1px;
                height: 14px;
                background: $White;
            }
            &:first-child {
                &:after {
                    display: none;
                }
            }
            &.active {
                opacity: 1;
                background: transparent;
                .circle {
                    background: $Success;
                    border: none;
                    span {
                        visibility: visible;
                    }
                }
            }
        }
        @media only screen and (max-width: 767px) {
            flex-direction: row !important;
            .nav-link {
                margin-bottom: 15px;
                &:after {
                    transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    left: -7px;
                    bottom: 11px;
                    width: 1px;
                    height: 8px;
                }
                .circle {
                    width: 30px;
                    height: 30px;
                    .ico {
                        display: none;
                    }
                    em {
                        display: inline-block;
                        font-style: normal;
                    }
                }
            }
        }
    }
    // Responsive Style
    @media only screen and (max-width: 1200px) {
        padding: 90px 40px 0;
        p {
            width: 100%;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 8% 150px;
        min-height: unset;
        .nav-brand {
            width: 130px;
        }
        h1 {
            margin-top: 50px;
            font-size: 24px;
        }
        p {
            font-size: 13px;
            line-height: 22px;
        }
    }
}

.right-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        &.lead {
            color: $LightGray;
            font-size: 14px;
            @media only screen and (max-width: 767px) {
                br {
                    display: none;
                }
            }
        }
    }
    .form-wrap {
        width: 445px;
        margin: 60px 0;
    }
    // Responsive Style
    @media only screen and (max-width: 1200px) {
        .form-wrap {
            width: 80%;
        }
    }
    @media only screen and (max-width: 767px) {
        position: relative;
        top: 0;
        width: 84%;
        left: 8%;
        margin-top: -140px;
        background: $White;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba($Gray, $alpha: 0.1);
        margin-bottom: 30px;
        .form-wrap {
            width: 93%;
            margin: 20px 0 20px;
        }
    }
}

// Custom Form Style
.custom-form {
    .form-label {
        font-size: 13px;
        color: $Gray;
        margin-bottom: 5px;
        .astrick {
            color: $Danger;
            font-size: 12px;
            margin-left: 3px;
        }
        small {
            font-size: 10px;
        }
    }
    .form-control {
        font-size: 14px;
        color: $Gray;
        height: 45px;
        border: 1px solid $StrokeGray;
        border-radius: 4px;
        &:focus {
            border-color: $StrokeGray;
            box-shadow: 0 0 7px rgba($Gray, $alpha: 0.09);
        }
        &.error {
            box-shadow: 0 0 8px rgba($Danger, $alpha: 0.15);
            border-color: rgba($Danger, $alpha: 0.2);
        }
        &.otp-input {
            width: 55px;
            height: 55px;
            border: none;
            box-shadow: 0 2px 14px rgba($Gray, $alpha: 0.12);
            margin: 10px 10px;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            @media only screen and (max-width: 767px) {
                width: 50px;
                height: 50px;
                margin: 10px 8px;
            }
        }
    }
    .input-group {
        &.with-ico {
            .form-control {
                position: relative;
                border-radius: 4px !important;
                margin-left: 0 !important;
                padding-right: 50px;
            }
            .input-group-text {
                color: $LightGray;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border: none;
                background: none;
                z-index: 99;
                span {
                    font-size: 22px;
                }
            }
        }
        &.two-input-ico {
            .input-group-text {
                background: transparent;
                border-color: $StrokeGray;
                font-size: 14px;
                &+.form-control {
                    border-left: none;
                }
            }
        }
    }
    .form-check {
        .form-check-input {
            width: 18px;
            height: 18px;
            border-color: #08213a;
            &:checked {
                background-color: $Primary;
                border-color: $Primary;
            }
            &:focus {
                box-shadow: none;
            }
        }
        &.tc {
            label {
                color: $LightGray;
                margin-left: 7px;
                margin-top: 4px;
                a {
                    color: $DarkGray;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .checkbox-wrap {
        .form-check-inline {
            display: inline-block;
            align-items: center;
            .form-check-input:checked[type=radio] {
                background-color: $White;
                background-image: url("../../../public/media/images/radio-dot.svg");
                background-size: 9px;
            }
            .form-check-label {
                font-size: 13px;
                color: $Gray;
                margin-left: 7px;
                margin-top: 4px;
            }
        }
    }
    p {
        color: $LightGray;
        a {
            color: $DarkGray;
            &:hover {
                text-decoration: none;
            }
        }
        &.error-msg {
            color: $Danger;
            font-size: 11px;
            margin-top: 2px;
        }
    }
    textarea {
        resize: none;
    }
}

// Add Profile Style
.add-profile {
    .left-wrap {
        padding-top: 70px;
        // Responsive Style Starts
        @media only screen and (max-width: 767px) {
            padding-top: 40px;
        }
    }
}
.right-wrap {
    align-items: flex-start;
    .form-wrap {
        width: 100%;
        margin: 50px 80px;
        .role {
            @include df-ac();
            p {
                @include df-ac-jc();
                @include trans-all();
                flex-flow: column;
                color: $Gray;
                font-size: 13px;
                cursor: pointer;
                padding: 15px 18px;
                border-radius: 4px;
                margin-right: 10px;
                opacity: 0.8;
                text-align: center;
                span {
                    font-size: 28px;
                    margin-bottom: 8px;
                }
                &.active, &:hover {
                    opacity: 1;
                    background: $Primary;
                    color: $White;
                }
            }
        }
        .render-switch {
            @include df-ac-jc();
            height: 45px;
            border: 1px solid $StrokeGray;
            border-radius: 4px;
            span {
                @include df-ac-jc();
                width: 100%;
                cursor: pointer;
                color: $LightGray;
                height: 100%;
                position: relative;
                z-index: 99;
                &:after {
                    content: "";
                    background: $Primary;
                    position: absolute;
                    left: 4px;
                    top: 4px;
                    width: calc(100% - 8px);
                    height: calc(100% - 8px);
                    border-radius: 4px;
                    z-index: -9;
                    opacity: 0;
                    @include trans-all();
                }
                &:hover:after, &.active:after {
                    opacity: 1;        
                }
                &:hover, &.active {
                    color: $White;
                }
            }
        }
        .add-btn {
            position: absolute;
            right: -18px;
            width: auto;
            padding: 0;
            height: auto;
            display: flex;
            bottom: 11px;
            text-decoration: none;
            color: rgba($LightGray, $alpha: 0.8);
            span {
                font-size: 22px;
                @include trans-all();
            }
            &:hover {
                color: $Gray;
            }
            &.delete {
                color: $Danger;
                &:hover {
                    color: $DangerDark;
                }
            }
        }
        .file-drag-container {
            @include df-ac-jc();
            flex-flow: column;
            border: 1px dashed $StrokeGray;
            font-size: 11px;
            border-radius: 4px;
            height: 120px;
            .ico {
                font-size: 32px;
                margin-bottom: 7px;
                color: $LightGray;
            }
            p {
                font-size: 12px;
                color: $LightGray;
                margin-bottom: 0;
                span {
                    color: $Gray;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            .default-file-input {
                opacity: 0;
                width: 44px;
                margin-left: -43px;
                position: relative;
                left: 39px;
                z-index: 9;
                cursor: pointer;
            }
        }
        .hint {
            font-size: 11px;
            color: $LightGray;
            font-style: italic;
        }
        .btn-group {
            .btn:first-child {
                border-right: 1px solid rgba($White, $alpha: 0.2)
            }
            .btn:last-child {
                margin-left: 0;
            }
        }
        .uploaded-file {
            display: inline-flex;
            align-items: center;
            position: relative;
            background: rgba($StrokeGray, $alpha: 0.4);
            border-radius: 4px;
            padding: 10px 30px 10px 10px;
            img {
                width: 23px;
                margin-right: 12px;
            }
            .circle-ico {
                @include df-ac-jc();
                position: absolute;
                right: -12px;
                top: -12px;
                background: $White;
                color: $Danger;
                padding: 0;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-shadow: 0 0 6px rgba($Gray, $alpha: 0.13);
                @include trans-all();
                span {
                    font-size: 20px;
                }
                &:hover {
                    background: $Danger;
                    color: $White;
                }
            }
        }
    }
    .custom-form {
        min-height: calc(100vh - 180px);
        position: relative;
        padding-bottom: 70px;
        .btm-float {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .uploaded-profile {
        display: inline-flex;
        box-shadow: 0 2px 6px rgba($Gray, $alpha: 0.13);
        position: relative;
        .pic-sec {
            display: flex;
            width: 175px;
            height: 175px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
        }
        .circle-ico {
            @include df-ac-jc();
            position: absolute;
            right: -12px;
            top: -12px;
            background: $White;
            color: $Danger;
            padding: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            box-shadow: 0 0 6px rgba($Gray, $alpha: 0.13);
            @include trans-all();
            span {
                font-size: 20px;
            }
            &:hover {
                background: $Danger;
                color: $White;
            }
        }
    }
    // Responsive Style Starts
    @media only screen and (max-width: 1199px) {
        .form-wrap {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
    @media only screen and (max-width: 991px) {
        .form-wrap {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
    @media only screen and (max-width: 767px) {
        .form-wrap {
            margin: 20px 0;
            padding: 0 5px;
            .role {
                p {
                    padding: 15px;
                    min-height: 100px;
                    margin-right: 5px;
                }
            }
            .custom-form {
                min-height: unset;
                .btm-float {
                    .btn {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                .addon-group {
                    background: rgba($LightGray, $alpha: 0.1);
                    margin: 0;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 4px;
                    .add-btn {
                        bottom: -11px;
                        top: auto;
                        background: $White;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 0 8px rgba($LightGray, $alpha: 0.2);
                        right: -9px;
                        &.delete {
                            bottom: auto;
                            top: -11px;
                        }
                    }
                }
            }
        }
    }
}


// Profile Style

// Header Style
header {
    .nav-brand {
        width: 110px;
        margin: 10px 0;
    }
    .navbar {
        background: $Primary;
        .navbar-collapse {
            flex-grow: unset;
            .navbar-nav {
                .nav-item {
                    margin-right: 40px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .nav-link {
                        font-size: 14px;
                        color: $White;
                    }
                }
            }
        }
        .profile-menu {
            .btn {
                display: flex;
                align-items: center;
                border:none;
                border-left: 1px solid rgba($White, $alpha: 0.13);
                border-radius: 0;
                padding: 8px 20px;
                margin-left: 20px;
                &:active {
                    border:none;
                    border-left: 1px solid rgba($White, $alpha: 0.13);
                }
                .img-wrap {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 50%;
                    border: 1px solid rgba($StrokeGray, $alpha: 0.2);
                    margin-right: 8px;
                }
                span {
                    color: $White;
                    font-size: 14px;
                }
                &:after {
                    filter: invert(1);
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    font-size: 13px;
                }
            }
        }
    }
    // Responsive Style Starts
    @media only screen and (max-width: 991px) {
        .navbar {
            .navbar-collapse {
                .navbar-nav {
                    .nav-item {
                        margin-right: 15px;
                        .nav-link {
                            font-size: 12px;
                        }
                    }
                }
            }
            .profile-menu {
                .btn {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .nav-brand {
            width: 90px;
        }
        .navbar {
            .hdr-right {
                flex-flow: row-reverse;
                .profile-menu {
                    .btn {
                        border: none;
                        padding: 10px;
                        margin-left: 0;
                        span {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
                .navbar-toggler {
                    filter: brightness(0) invert(1);
                    border-radius: 0;
                    border-width: 0;
                    border-left-width: 1px;
                    &:focus {
                        box-shadow: none;
                    }
                }
                .navbar-collapse {
                    position: absolute;
                    left: 0px;
                    top: 60px;
                    background: $PrimaryHover;
                    width: 100%;
                    z-index: 99;
                    .navbar-nav {
                        .nav-item {
                            margin: 0;
                            border-bottom: 1px solid rgba($White, $alpha: 0.13);
                            .nav-link {
                                padding: 13px 20px;
                            }
                        }
                    }
                }
            } 
        }
    }
}

// Content Style
// Toggle Switch Style
.content-sec {
    background: $BGgray;
    min-height: calc(100vh - 56px);
}
.toggle {
    position: relative;
    overflow: hidden;
    input[type="checkbox"] {
        position: relative;
        appearance: none;
        width: 75px;
        height: 27px;
        background: rgba($LightGray, $alpha: 0.4);
        border-radius: 30px;
        cursor: pointer;
        @include trans-all();
        &:checked[type="checkbox"] {
            background: $Success;
            &:after {
                left: 51px;
                background: $White url("../../../public/media/images/tick-ico.svg") center center no-repeat;
            }
            & ~ label.yes {
                opacity: 1;
                left: 20px;
            }
        }
        &:not(:checked[type="checkbox"]) {
            & ~ label.no {
                opacity: 1;
                left: 36px;
            }
        }
        &::after {
            position: absolute;
            content: "";
            left: 3px;
            top: 3px;
            width: 21px;
            height: 21px;
            background: $White url("../../../public/media/images/minus-ico.svg") center center no-repeat;
            border-radius: 50%;
            @include trans-all();
          }
    }
    label {
        position: absolute;
        pointer-events: none;
        color: $White;
        @include trans-all();
        opacity: 0;
        &.no {
            left: 30px;
            top: 5px;
        }
        &.yes {
            left: 24px;
            top: 5px;
        }
    }
}

.profile-content {
    .snippet {
        background: $White;
        border: 1px solid rgba($StrokeGray, $alpha: 0.8);
        border-radius: 4px;
        padding: 25px;
        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            a {
                color: $LightGray;
                font-size: 12px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .cont {
            .ico-row {
                justify-content: flex-start;
                @include df-ac();
                .ico {
                    @include df-ac-jc();
                    width: 30px;
                    height: 30px;
                    background: rgba($Primary, $alpha: 0.1);
                    border-radius: 50%;
                    span {
                        font-size: 16px;
                    }
                }
                p {
                    margin-left: 12px;
                }
            }
            .badge-sec {
                .chips {
                    background: rgba($Primary, $alpha: 0.1);
                    border-radius: 4px;
                    padding: 8px 10px;
                    color: $Primary;
                    font-size: 12px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    small {
                        font-size: 12px;
                        color: rgba($Primary, $alpha: 0.5);
                        display: block;
                    }
                }
            }
            .expect-salary {
                @include df-ac();
                justify-content: space-between;
                padding: 18px 20px;
                background: rgba($Primary, $alpha: 0.1);
                border-radius: 4px;
            }
            .with-dot {
                li {
                    font-size: 12px;
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background: $LightGray;
                        border-radius: 50%;
                        margin: 0 4px 0 6px;
                        position: relative;
                        top: -1px;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
                &.fs-13 {
                    li {
                        font-size: 13px;
                    }
                }
            }
            &.profile-sec {
                @include df-ac();
                justify-content: space-between;
                margin-bottom: 45px;
                .avatar-sec {
                    @include df-ac();
                    width: calc(100% - 180px);
                    .img-wrap {
                        @include df-ac-jc();
                        width: 94px;
                        height: 94px;
                        margin-right: 20px;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        border: 3px solid $White;
                        box-shadow: 0 0 4px rgba($Gray, $alpha: 0.2);
                    }
                    .avatar-cont {
                        width: calc(100% - 120px);
                    }
                }
                // Responsive Style Starts
                @media only screen and (max-width: 1199px) {
                    h2 {
                        font-size: 18px;
                    }
                    .with-dot {
                        li {
                            font-size: 11px;
                        }
                    }
                }
                @media only screen and (max-width: 767px) {
                    justify-content: flex-start;
                    flex-flow: column;
                    margin-bottom: 0;
                    .avatar-sec {
                        @include df-ac-jc();
                        flex-flow: column;
                        width: 100%;
                        .img-wrap {
                            margin: 0;
                        }
                        .avatar-cont {
                            margin-top: 20px;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            .exp-timeline {
                .timeline-item {
                    position: relative;
                    padding-bottom: 35px;
                    padding-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 7px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background: $Primary;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        left: 3px;
                        top: 20px;
                        bottom: -1px;
                        background: rgba($Primary, $alpha: 0.2);
                        width: 1px;
                    }
                    &:last-child:after {
                        display: none;
                    }
                }
            }
            .resume-snip {
                display: inline-flex;
                align-items: center;
                border-radius: 4px;
                background: rgba($Primary, $alpha: 0.1);
                padding: 10px;
                .ico {
                    width: 23px;
                    margin-right: 12px;
                }
            }
        }
        // Responsive Style
        @media only screen and (max-width: 767px) {
            padding: 20px;

        }
    }
    .pill-navbar {
        margin-top: -48px;
        .nav-pills {
            width: 100%;
            @include df-ac();
            .nav-item {
                width: 25%;
                @include df-ac();
                .nav-link {
                    @include df-ac-jc();
                    width: 100%;
                    color: $LightGray;
                    padding-bottom: 15px;
                    border-radius: 0;
                    border-bottom: 2px solid transparent;
                    .ico {
                        font-size: 22px;
                        margin-right: 7px;
                    }
                    &.active {
                        background: transparent;
                        border-bottom-color: $Primary;
                        color: $Primary;
                    }
                }
            }
        }
        // Responsive Style Starts
        @media only screen and (max-width: 1199px) {
            margin-top: -44px;
            .nav-pills {
                .nav-item {
                    .nav-link {
                        font-size: 12px;
                        .ico {
                            font-size: 18px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 991px) {
            margin-top: 0;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: -7px;
        }
    }
}

// Footer Style
footer {
    padding-top: 8px;
    padding-bottom: 8px;
    background: $White;
    border-top: 1px solid rgba($StrokeGray, $alpha: 0.8);
    p {
        font-size: 11px;
        text-align: center;
        color: rgba($LightGray, $alpha: 0.6);
    }
}

// Find Match Style
.left-search-wrap {
    background: $White;
    padding: 20px 20px 80px 20px;
    border-radius: 4px;
    box-shadow: 0 4px 15px rgba($DarkGray, $alpha: 0.08);
    position: relative;
    .custom-form {
        .form-label {
            font-size: 12px;
        }
        .form-control {
            font-size: 13px;
        }
    }
    .btm-btn {
        border-top: 1px solid $StrokeGray;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        align-items: center;
        .btn {
            width: 48%;
        }
    }
    // Responsive Style
    @media only screen and (max-width: 991px) {
        .custom-form {
            height: 330px;
            overflow-y: auto;
        }
    }
}

.result-listing {
    .title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .sort-by {
            @include df-ac();
            .form-label {
                white-space: nowrap;
                font-size: 13px;
                margin-bottom: 0;
                color: $LightGray;
            }
            .form-select {
                background-color: transparent;
                font-size: 13px;
                color: $LightGray;
                padding: 0 14px 0 4px;
                border: none;
                background-position: right 5px;
                background-size: 10px;
            }
        }
    }
    .candidate-snippet {
        background: $White;
        border: 1px solid $StrokeGray;
        border-radius: 4px;
        .head {
            @include df-ac();
            justify-content: space-between;
            padding: 20px 25px;
            border-bottom: 1px solid $StrokeGray;
            .avatar-encl {
                @include df-ac();
                width: 50%;
                .avatar-img {
                    @include df-ac-jc();
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    border: 2px solid $White;
                    box-shadow: 0 0 4px rgba($Gray, $alpha: 0.2);
                    overflow: hidden;
                    object-fit: cover;
                    margin-right: 8px;
                }
                .avatar-detail {
                    display: flex;
                    justify-content: flex-start;
                    flex-flow: column;
                    width: calc(100% - 80px);
                }
            }
            .btn-encl {
                @include df-ac();
                .btn {
                    font-size: 12px;
                    padding: 10px 20px;
                    min-height: 34px;
                }
            }
        }
        .cont {
            padding: 20px 25px;
            .badge {
                margin: 0 5px 5px 0;
                &.tech {
                    background: rgba($Primary, $alpha: 0.15);
                    padding: 5px 10px;
                    color: $Gray;
                    font-size: 12px;
                    border-radius: 4px;
                    font-weight: 400;
                    display: inline-block;
                }
            }
        }
    }
    // Responsive Style
    @media only screen and (max-width: 767px) {
        .candidate-snippet {
            .head {
                padding: 15px;
                flex-flow: column;
                align-items: flex-start;
                .avatar-encl {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .cont {
                padding: 15px;
            }
        }
    }
}

.no-data {
    @include df-ac-jc();
    flex-flow: column;
    min-height: calc(100vh - 130px);
    p {
        font-size: 13px;
        color: $LightGray;
    }
}

.pagination {
    .page-item {
        @include df-ac();
        .page-link {
            border: none;
            background: transparent;
            font-size: 13px;
            color: $LightGray;
            &.btn-asphalt {
                background: $Primary;
                padding: 8px 25px;
                border-radius: 30px;
                color: $White;
            }
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        &.disabled {
            .page-link {
                &.btn-asphalt {
                    color: $LightGray;
                    background: transparent;
                }
            }
        }
        &.active {
            .page-link {
                text-decoration: underline;
                color: $Primary;
                font-weight: 500;
            }
        }
    }
}
.fv-plugins-message-container {
	span {
			color: $Danger;
		}
	}
  .close {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 1.5rem;
    color: #721c24;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .close:hover {
    transform: rotate(90deg);
  }
  
  .close span {
    position: relative;
    top: -1px;
  }
  .react-tel-input .form-control {

    font-size: 16px;
    background: #FFFFFF;
    border: 1px  solid #e3e3e3;
    border-radius: 5px;
   display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    outline: none;
    
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
}
.react-tel-input .form-control:focus {
    border-color: #E3E3E3 !important;
    box-shadow: 0 0 7px rgba(47, 47, 47, 0.09) !important;
    background-color:none !important;
    
}

@media screen and (max-width: 764px) {
    .text-end {
        padding-top: 20px !important;
        text-align: right!important;
    }
  }
  